.video-player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.8);

  .player {
    position: relative;
    width: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: none;
    }
  
    .close-button{
      position: absolute;
      top: -36px;
      right: -36px;
    }
  }
}

